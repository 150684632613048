import React from 'react'

/**
 * 
 * @param boolean isSelected
 * @param boolean disabled
 * @param 'radio|checkbox' type
 * @param number idx
 * @param string icon
 * @param string label
 * 
 * @returns 
 */
export default function SelectablePill(props) {
    return (
        <li className={`dropdown-item check-item -button ${props?.class ? props?.class : ''} ${props.isSelected ? '-selected' : '-unselected'}`}
            >
            <a className={`check styled ${(props.name == 'kitchen') ? '_change' : ''}`} >
                <input className="radio-primary"
                    onClick={()=> props.onSelect(props.idx)}
                    disabled={props.disabled}
                    type={props.type} 
                    id={(props.id ?? "quote-type") + props.idx} 
                    name={props.name ?? "quote-type"} 
                    value={props.idx} 
                    readOnly 
                    checked={props.isSelected ? true : false}>                         
                </input>
                <label htmlFor={(props.id ?? "quote-type") + props.idx}>
                    <div>
                        {props.children &&
                            props.children
                        }

                        {props.icon && (
                            <i className={` icons fa-solid ${props.icon}`}></i>
                        )}
                        {!props.children &&
                            <span>{props.label}</span>
                        }
                    </div>
                </label>
            </a>
        </li>
    )
}
